<script setup lang="ts">
import { Icon } from 'webcc-ui-components'
import { DATE_FORMAT_DDMMYYYY } from '~/constants'

const { t } = useI18n()

defineEmits<{
  (e: 'click'): void
}>()

const props = withDefaults(
  defineProps<{
    date?: string | undefined
    isUpcomingPaymentsNotEmpty?: boolean
    amount: number
    currencySymbol: string
  }>(),
  {
    date: undefined,
    isUpcomingPaymentsNotEmpty: true,
  },
)

const title = computed(() =>
  props.isUpcomingPaymentsNotEmpty
    ? t('components.payments.totalUpcomingSum')
    : t('components.payments.totalUpcomingSumEmpty'),
)
</script>

<template>
  <div data-id="payments-item">
    <div
      tabindex="0"
      class="flex w-full cursor-pointer items-center space-x-3 border-b border-bgr-300 text-txt-400"
      @click="$emit('click')"
      @keyup.enter="$emit('click')"
    >
      <div class="flex w-full items-center justify-between px-3 py-2.5">
        <p class="text-sm font-medium leading-3.5">
          {{ date ? $date.formatDate(date, DATE_FORMAT_DDMMYYYY) : title }}
        </p>

        <p
          v-if="isUpcomingPaymentsNotEmpty"
          data-id="payment-item-amount"
          class="whitespace-nowrap text-base font-normal leading-4.5 text-txt-100"
        >
          {{
            currencyConverter(
              toFixed({
                number: amount,
                forceConvert: true,
              }),
              currencySymbol,
            )
          }}
        </p>
      </div>
      <Icon v-if="isUpcomingPaymentsNotEmpty" graphic="arrow-right" size="sm" />
    </div>
  </div>
</template>
