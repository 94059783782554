<script setup lang="ts">
import { Btn } from 'webcc-ui-components'
import type { OverviewStatements } from '~/composables/payment-overview'
import { OtherFormType } from '~/schema'

const { t } = useI18n()

const props = defineProps<{
  statementsList: OverviewStatements | undefined
}>()

defineEmits<{ (e: 'payment-click', value: string): void }>()

const isStatementsNotEmpty = computed(
  () => Object.keys(props.statementsList ?? {}).length > 0,
)
const noPaymentsText = computed(() =>
  serializeSentenceWithLink(t('components.paymentStatement.emptyDescription')),
)
</script>

<template>
  <div data-id="payment-statement-list">
    <OnDevices v-if="isStatementsNotEmpty" rule=">=md" show-class="flex">
      <div
        class="flex w-full justify-between border-b border-bgr-300 px-3 py-2.5 text-sm font-medium leading-3.5 text-txt-400"
      >
        <p>{{ $t('components.paymentStatement.paymentDate') }}</p>
        <p>{{ $t('components.paymentStatement.payment') }}</p>
      </div>
    </OnDevices>

    <div v-if="isStatementsNotEmpty">
      <PaymentItem
        v-for="statement in statementsList"
        :key="statement.date"
        :date="statement.date"
        :amount="statement?.amount"
        :currency-symbol="statement.currency.symbol"
        :payment-item="statement"
        @click="$emit('payment-click', statement.date)"
      />
    </div>
    <BasicDataFallback
      v-else
      :title="$t('components.paymentStatement.emptyTitle')"
    >
      <p class="text-center text-base">
        {{ noPaymentsText.labelBefore }}

        <Btn
          size="md"
          variant="link"
          class="font-normal"
          :label="noPaymentsText.linkText"
          @click="
            navigateTo(`${$ROUTE.CONTACT_US}?type=${OtherFormType.Other}`)
          "
        />

        {{ noPaymentsText.labelAfter }}
      </p>
    </BasicDataFallback>
  </div>
</template>
