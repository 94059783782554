import { useInfiniteQuery } from '@tanstack/vue-query'
import type { MaybeRef } from 'vue'
import { pick } from 'lodash-es'
import { type components } from '~/schema'
import { useApiFetch } from '~/composables/api'
import { PathsApiPaymentsPayoutDateGetParametersQueryType as PayoutType } from '~/schema'
import type {
  OverviewStatements,
  PaymentGroup,
} from '~/composables/payment-overview'

export type PayoutStatements = components['schemas']['PayoutStatementsResponse']
export type Statement = components['schemas']['PaymentStatement']

type NormalizedPayment = PayoutStatements & {
  currencySymbol: string
  isEmptyStatements: boolean
}

export { PayoutType }

export const PAGE_SIZE = 12

export function usePaymentStatements(params: {
  date: MaybeRef<string>
  type: MaybeRef<PayoutType | undefined>
}) {
  const fetch = useApiFetch()
  return useInfiniteQuery({
    enabled: computed(() => Boolean(unref(params.date))),
    queryKey: ['payment-statement', params.date, params.type],
    initialPageParam: 0,
    retry: false,
    queryFn({ signal, pageParam = 0, queryKey: [_, date, filter] }) {
      return fetch<PayoutStatements>(`/api/payments/${date}`, {
        signal,
        query: {
          page: pageParam,
          size: PAGE_SIZE,
          type: filter || null,
        },
      })
    },
    getNextPageParam(lastPageData, _allPages) {
      if (!lastPageData.rest) return

      return lastPageData.page + 1
    },
    select({ pages }): NormalizedPayment {
      const lastPageResults = pages[pages.length - 1]
      const payment = {
        ...lastPageResults,
        statements: pages.reduce(
          (acc, page) => [...acc, ...(page.statements || [])],
          [] as Statement[],
        ),
      }

      return {
        ...payment,
        isEmptyStatements: !payment.statements.length,
        currencySymbol: payment.currency?.symbol || '',
      }
    },
  })
}

export type PayoutResponse = components['schemas']['PayoutsResponse']
export type PayoutPagination = Required<
  Pick<PayoutResponse, 'page' | 'size' | 'total' | 'rest'>
>

const defaultPagination: PayoutPagination = {
  page: 0,
  size: PAGE_SIZE,
  total: 0,
  rest: 0,
}

export function usePaymentPayouts(params: {
  from: MaybeRef<string>
  to: MaybeRef<string>
}) {
  const fetch = useApiFetch()
  return useInfiniteQuery({
    queryKey: ['payment-payouts', params.from, params.to] as const,
    initialPageParam: 0,
    queryFn({ signal, pageParam = 0, queryKey: [_, from, to] }) {
      return fetch<PayoutResponse>(`/api/payments/payouts`, {
        signal,
        query: {
          page: pageParam,
          size: PAGE_SIZE,
          from,
          to,
        },
      })
    },
    getNextPageParam(lastPageData, _allPages) {
      if (!lastPageData.rest) return

      return lastPageData.page + 1
    },
    select(data) {
      const { pages } = data
      const payments = data.pages.reduce(
        (acc, curr) => acc.concat(curr.payments || []),
        [] as PaymentGroup[],
      )

      const serializedStatements =
        payments.reduce(
          (acc, item) => ({
            ...acc,
            [item.date]: item,
          }),
          {} as OverviewStatements,
        ) ?? ({} as OverviewStatements)

      return {
        statements: serializedStatements,
        statementsCount: Object.keys(serializedStatements).length,
        currencySymbol: pages[pages.length - 1].currency?.symbol,
        pagination: pick(pages[pages.length - 1] || defaultPagination, [
          'page',
          'size',
          'total',
          'rest',
        ]) as PayoutPagination,
      }
    },
  })
}
